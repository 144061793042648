import React from "react"
import { graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import tw, { styled } from 'twin.macro'

import {Layout} from '@components/layout/layout'
import { colorGold, darkGrey, dividerColor, lightGrey } from "@styles/color"
import { StyledLink, StyledWhiteLink, MainContent } from "@components/layout/style"
import { CircleCheck } from "@components/circle-check"


const StyledImage = styled.div`
  ${tw`hidden lg:block`}
`

const StyledHero = styled.div`
  ${tw`mb-12 hidden lg:block`}
`

const Label = styled.h1`
  ${tw`text-sm uppercase mb-6 tracking-widest`}
  color: ${colorGold};
`

const Header = styled.h2`
  ${tw`text-4xl text-gray-900 sm:mb-12 mb-8 sm:text-left text-center sm:mt-0 mt-6`}
`

const Description = styled.div`
  ${tw` text-gray-400 mb-14 leading-6`}
  text-indent: 2rem;
`

const OfertList = styled.section`
  ${tw`flex justify-between mb-16 lg:flex-row lg:gap-0 flex-col gap-8`}
  a {
    ${tw`block w-4/5`}

  }
`
const OfertWith = 328;
const OfertCard = styled.div`
  ${tw`lg:p-0 lg:pb-0.5 p-0.5 h-full`}

  width: 85%;
  display: block;
  cursor: pointer;
  background-color: ${lightGrey};

  img {
    width: 100%;
    aspect-ratio: 1;
    background-color: lightblue;
  }

  @media (min-width: 640px) {
    width: ${OfertWith}px;
  }
`

const OfertDescription = styled.div`
  ${tw`px-16 px-10 select-none`}
  h4 {
    ${tw`sm:text-base sm:text-left text-center text-lg text-gray-50 uppercase my-6 whitespace-nowrap`}
  }

  span:nth-of-type(1) {
    ${tw`text-gray-300 text-sm mb-8 font-light block`}
  }

  ${StyledWhiteLink} {
    ${tw`mb-8 block tracking-widest transition-all duration-300 ease-in-out sm:text-left text-center mx-auto`}
    color: ${colorGold};
    margin-left: 0;
    padding: 0;
    position: relative;
    &:after {
      ${tw`text-4xl ml-4 mt-4 transition-transform duration-300`}
      position: absolute;
      bottom: -11px;
      content: " \\1F812";
      display: inline-block;
      transform: scaleY(0.7);
    }
    &:hover {
      &:after {
        transform: scaleY(0.7) translateX(8px);
      }
    }
  }

`

const OtherOfertList = styled.section`
  ${tw`flex sm:mt-14 mt-0 mb-12 lg:flex-row flex-col justify-center mx-auto` }

  ul:first-of-type {
    ${tw`mr-20 pr-20`}
  }

  ul {
    ${tw`sm:pl-0 pl-4`}
  }

  li {
    ${tw`flex items-center my-6 text-xl`}

    position: relative;

    svg {
      transform: scale(2);
    }

    ${StyledLink} {
      ${tw`text-xl mr-6`}

      &:after {
        ${tw`text-4xl ml-4 mt-4 transition-transform duration-300`}
        position: absolute;
        bottom: 19px;
        content: " \\1F812";
        display: inline-block;
        transform: scaleY(0.7);
      }

      &:hover {
        &:after {
          transform: scaleY(0.7) translateX(8px);
        }
      }

    }


    li:first-of-type {
      ${tw` mt-0`}

    }
  }
`

const ContactSection = styled.section`
  ${tw`flex flex-col content-center justify-center items-center my-24 p-10`}
  background-color: ${lightGrey};

  h4 {
    ${tw`sm:text-xl text-2xl text-gray-50 text-center mb-8`}
  }

  ul {
    ${tw`flex justify-center flex-col `}
    li:nth-of-type(1) {
      ${tw`
        border-b  border-solid
        mr-0 pr-0 `}
      border-color: ${dividerColor};
    }
    li {
      ${tw`py-2`}
      width: 360px;
    }
    li:first-of-type {
      ${tw`text-center `}
    }
    li:last-of-type {
      ${tw`text-center `}
    }
    a {
      ${tw`text-xl`}
      color: ${colorGold};
      cursor: pointer;
      display: block;
    }
  }

  span {
    ${tw`text-gray-300 text-center leading-6 sm:text-sm text-base mt-16 mb-6 font-light block`}
    max-width: 440px;
  }
`

const HomeTemplate:React.FC = ({data}) => {
  return (
  <Layout
    data={data}
    prop={{
      canonicalUrl: 'https://www.mediroot.pl/',
      title: 'Stomatolog Wągrowiec - gabinet stomatologiczny - dentysta | Mediroot',
      description: 'Mediroot ➤ Stomatolog Wągrowiec - Stomatologia zachowawcza ✔ Stomatologia dziecięca ✔ Ortodoncja ✔ Protetyka stomatologiczna ✔ Zapoznaj się z ofertą i zadzwoń już dziś! ☎'
    }}>
    <StyledHero>
      <StaticImage
        src="../assets/images/hero.jpg"
        layout="fullWidth"
        placeholder="blurred"
        alt="stomatolog Wągrowiec, gabinet stomatologiczny Wągrowiec, dentysta Wągrowiec" />
    </StyledHero>

    <MainContent>
      <Label>
        Stomatolog Wągrowiec - gabinet stomatologiczny
      </Label>
      <Header>
        Nasza oferta
      </Header>
      <Description>
      Mediroot to gabinet stomatologiczny z Wągrowca, który świadczy usługi z zakresu stomatologii zachowawczej,
chirurgicznej, estetycznej, a także ortodoncji, implantologii czy protetyki. Zaawansowany, specjalistyczny sprzęt,
na którym pracujemy, jest gwarancją najwyższych standardów. Sympatyczna atmosfera, przytulne gabinety i
pełen profesjonalizm sprawiają, że wizyta przebiega bez zbędnego stresu, a możliwość rozłożenia płatności na
raty pozwala każdemu skorzystać z naszych usług. Ponadto dokładamy wszelkich starań, aby czas oczekiwania
na wizytę był jak najkrótszy.
      </Description>
      <OfertList>
        <Link to="/oferta/implantologia-wagrowiec">
          <OfertCard>
            <StyledImage>
              <StaticImage
                src="../assets/images/implantologia.png"
                width={OfertWith}
                height={OfertWith}
                placeholder="blurred"
                layout="fixed"
                alt="Implantologia cover"/>
            </StyledImage>

            <OfertDescription>
              <h4>Implantologia</h4>
              <span>
                Implanty w przeciwieństwie do protez czy mostów są długotrwałym rozwiązaniem. W naszym gabinecie stomatologicznym wykonasz m.in. 
              mosty protetyczne na implantach czy różnego rodzaju protezy ruchome na implantach
              </span> 
              <StyledWhiteLink to="/oferta/implantologia-wagrowiec"> Czytaj więcej</StyledWhiteLink>
            </OfertDescription>
          </OfertCard>
        </Link>

        <Link to="/oferta/ortodoncja-wagrowiec">
          <OfertCard>
            <StyledImage>
              <StaticImage
                src="../assets/images/ortodoncja.jpg"
                width={OfertWith}
                height={OfertWith}
                placeholder="blurred"
                layout="fixed"
                alt="Ortodoncja cover"/>
            </StyledImage>

            <OfertDescription>
              <h4>Ortodoncja</h4>
              <span>
                Leczenie wad zgryzu to nie tylko kwestia estetyki, ale również zdrowia jamy ustnej. W Mediroot przed podjęciem ostatecznej decyzji o 
                leczeniu ortodontycznym dokładnie diagnozujemy pacjenta m.in. za pomocą badania radiologicznego. 
                Pamiętaj, że rozpoczęcie leczenia wad zgryzu możliwe jest w każdym wieku.
              </span>
              <StyledWhiteLink to="/oferta/ortodoncja-wagrowiec"> Czytaj więcej</StyledWhiteLink>

            </OfertDescription>
          </OfertCard>
        </Link>

        <Link  to="/oferta/protetyka-wagrowiec">
          <OfertCard>
            <StyledImage>
              <StaticImage
                src="../assets/images/protetyka.jpg"
                width={OfertWith}
                height={OfertWith}
                placeholder="blurred"
                layout="fixed"
                alt="Protetyka cover"/>
            </StyledImage>

            <OfertDescription>
              <h4>Protetyka stomatologiczna</h4>

              <span>
                Wykonujemy korony stomatologiczne, protezy szkieletowe, natychmiastowe oraz porcelanowe mosty, które stosuje się nie tylko w 
                przypadku utraty naturalnych zębów, ale również w przypadku ich uszkodzenia, kiedy nie nadają się one do leczenia zachowawczego
              </span>
              <StyledWhiteLink to="/oferta/protetyka-wagrowiec"> Czytaj więcej</StyledWhiteLink>

            </OfertDescription>
          </OfertCard>
        </Link>

      </OfertList>

      <Header>
        Pozostałe usługi
      </Header>

      <OtherOfertList>
        <ul>
          <li>
            <CircleCheck></CircleCheck>
            <StyledLink to="/oferta/endodoncja-wagrowiec">Endodoncja</StyledLink>
          </li>
          <li>
            <CircleCheck></CircleCheck>
            <StyledLink to="/oferta/stomatologia-estetyczna">Stomatologi estetyczna</StyledLink>
          </li>
          <li>
            <CircleCheck></CircleCheck>
            <StyledLink to="/oferta/stomatologia-chirurgiczna">Stomatologi chirurgiczna</StyledLink>
          </li>
          </ul>
        <ul>
          <li>
            <CircleCheck></CircleCheck>
            <StyledLink to="/oferta/stomatologia-zachowawcza">Stomatologi zachowawcza</StyledLink>
          </li>
          <li>
            <CircleCheck></CircleCheck>
            <StyledLink to="/oferta/stomatologia-dziecieca">Stomatologi dziecieca</StyledLink>
          </li>
          <li>
            <CircleCheck></CircleCheck>
            <StyledLink to="/oferta/sedacja-wziewna">Sedacja wziewna</StyledLink>
          </li>
        </ul>

      </OtherOfertList>

      <Header>
        Gabinet stomatologiczny Wągrowiec – ile kosztuje wizyta u dentysty?
      </Header>
      <Description>
            Leczenie zębów w ramach Narodowego Funduszu Zdrowia wiąże się zazwyczaj z długim czasem oczekiwania
          na wizytę. Niestety bardzo często ból wymusza wizytę w prywatnym gabinecie stomatologicznym. Cena usługi w
          takiej placówce zależy od bardzo wielu czynników. Przede wszystkim od rodzaju problemu, ale także od
          lokalizacji gabinetu – województwa, a także miejscowości. W niewielkich miastach usługi mogą być nieco niższe
          niż w dużych aglomeracjach. Nie bez znaczenia będzie również renoma danej placówki i pracujących tam
          dentystów.
      </Description>

      <Header>
        Stomatolog Wągrowiec – dlaczego warto wybrać Mediroot?
      </Header>
      <Description>
          Stomatolog w Wągrowcu, który kompleksowo dba o zdrowie jamy ustnej setek pacjentów to Mediroot. W naszej
        klinice specjalizujemy się w wielu dziedzinach stomatologii – zachowawczej, estetycznej, chirurgicznej. Dla tych
        pacjentów, których strach powstrzymuje przed regularnymi wizytami u dentysty, przygotowaliśmy możliwość
        sedacji wziewnej, czyli podania gazu rozweselającego, który przyniesie odprężenie i odwróci uwagę od
        wykonywanego zabiegu. 
      </Description>

      <Description>
          O zęby naszych pacjentów dbamy za pomocą najnowocześniejszych technologii i
        dokładamy wszelkich starań, aby czas w poczekalni oraz samym gabinecie upływał w przyjemnej atmosferze. W
        Mediroot zapewniamy, że każda wizyta u nas przybliża Cię do zdrowego i pięknego uśmiechu!
      </Description>

      <ContactSection>
        <h4>
          Zostań naszym klientem
        </h4>
        <ul>
          <li>
            <a href="tel:61 306 71 01">61 306 71 01</a>
          </li>

          <li>
            <a href="mailto:wagrowiec.mediroot@gmail.com">wagrowiec.mediroot@gmail.com</a>
          </li>
        </ul>

        <span>
          Zapraszamy do kontaktu i gwarantujemy, że jeśli raz zdecydujesz się na Mediroot, już zawsze zostaniesz z nami!
        </span>
      </ContactSection>
    </MainContent>

  </Layout>
  );
};

export default HomeTemplate;


export const query = graphql`
  query {
    logo: file(relativePath: { eq: "images/logo3.png" }) {
      childImageSharp {
        fluid( maxHeight: 92) {
          src
          sizes
          srcSet
          srcWebp
          base64
          aspectRatio
        }
      }
    }
  }
  `
